import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IModalService} from '@core/interfaces/default.interface';
import {ModalComponent} from '@shared/components/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {
  }

  open(options: IModalService): Promise<boolean> {
    const {component, modalOptions = {}, title, componentOptions = {}} = options;

    const defModalOptions = {centered: true, scrollable: true,};

    this.modalRef = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      keyboard: true,
      ...defModalOptions,
      ...modalOptions,
    });
    this.modalRef.componentInstance.data = {
      component,
      componentOptions,
      modalOptions,
      title,
    };

    return this.modalRef.result;
  }

  close() {
    this.modalRef.close(true);
  }

  closeAll() {
    this.modalService.dismissAll();
  }
}
