<div class="modal-header align-items-center bg-primary" style="padding: 6px">
  <h2 class="modal-title text-[18px] text-white">{{ data.title | translate }}</h2>
  <button
    (click)="close()"
    class="btn bg-transparent outline-none border-none p-0"
    type="button">
    <keen-icon class="text-[32px] text-white" name="cross-square" type="duotone"/>
  </button>
</div>

<ng-template appHost></ng-template>
